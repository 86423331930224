import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface PaymentAcknowledgeState {
  paymentAck: boolean;
}

interface PaymentAcknowledgeAction {
  setPaymentAck: (value: boolean) => void;
  reset: () => void;
}

export const usePaymentAcknowledgeStore = create<
  PaymentAcknowledgeState & PaymentAcknowledgeAction
>()(
  devtools(
    persist(
      (set) => ({
        paymentAck: false,
        setPaymentAck: (paymentAckValue) =>
          set({
            paymentAck: paymentAckValue,
          }),
        reset: () => set({ paymentAck: false }),
      }),
      {
        name: "paymentAcknowledgeStore",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
