import { useHmpOwnershipStore } from "./hmpOwnershipStore";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { MemberpackObtainingMethod } from "../../configuration/useConfigurationQuery";

export type HmpOwnershipPayload = {
  memberpackObtainingMethod: MemberpackObtainingMethod;
  idNumber: string;
  onlineCode: string;
};

export const hmpOwnershipFromStore = () => {
  return {
    memberpackObtainingMethod:
      useHmpOwnershipStore.getState().memberpackObtainingMethod,
    idNumber: useHmpOwnershipStore.getState().idNumber,
    onlineCode: useHmpOwnershipStore.getState().onlineCode,
  };
};

export const createHmpOwnershipFromForm = ({
  memberpackObtainingMethod,
  idNumber,
  onlineCode,
}: WithTypeContaining<HmpOwnershipPayload>): HmpOwnershipPayload => {
  return {
    memberpackObtainingMethod,
    idNumber,
    onlineCode,
  };
};
