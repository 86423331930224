import { create } from "zustand";
import { devtools, persist, StorageValue } from "zustand/middleware";
import { Pickup } from "../models/Pickup";
import { PickupResponse } from "../../queries/useMemberpacksQuery";
import { Memberpack } from "../../models/Memberpack";
import { PickupDetails } from "../../../../types/pickupDetails";

interface PickupState {
  pickups: Map<string, Pickup>;
  chosenPickupLocationId?: string;
  chosenMemberpack: Memberpack;
  pickupDetails: PickupDetails;
}

interface PickupActions {
  setPickups: (pickups: PickupResponse[]) => void;
  selectLocationId: (locationId: string) => void;
  setPickupDetails: (pickupDetails: PickupDetails) => void;
  reset: () => void;
}

const mapToPickup = (pickupResponse: PickupResponse): Pickup => {
  return {
    memberpack: {
      pricedMemberpackId: pickupResponse.pricedMemberpackId,
      isoLocale: pickupResponse.isoLocale,
      sku: pickupResponse.sku,
      description: pickupResponse.description,
      currencyCode: pickupResponse.currencyCode,
      currencySymbol: pickupResponse.currencySymbol,
      price: pickupResponse.price,
    },
    pickupLocation: pickupResponse.pickupLocation,
  };
};

export const usePickupStore = create<PickupState & PickupActions>()(
  devtools(
    persist(
      (set, get) => ({
        pickups: new Map<string, Pickup>(),
        chosenPickupLocationId: "",
        chosenMemberpack: <Memberpack>{},
        pickupDetails: <PickupDetails>{},
        setPickups: (pickups: PickupResponse[]) => {
          const pickupMap = new Map<string, Pickup>();
          pickups.forEach((pickup) => {
            pickupMap.set(
              pickup.pickupLocation.pickupLocationId,
              mapToPickup(pickup)
            );
          });
          set({ pickups: pickupMap });
          set({ chosenMemberpack: pickups[0] });
        },
        selectLocationId: (locationId: string) => {
          const pickup = get().pickups.get(locationId);
          if (pickup) {
            set({ chosenPickupLocationId: locationId });
            set({ chosenMemberpack: pickup.memberpack });
          }
        },
        setPickupDetails: (pickupDetails: PickupDetails) => {
          set({ pickupDetails });
        },
        reset: () => {
          set({ pickups: new Map<string, Pickup>() });
          set({ chosenPickupLocationId: "" });
          set({ chosenMemberpack: <Memberpack>{} });
          set({ pickupDetails: <PickupDetails>{} });
        },
      }),
      {
        name: "pickup-storage",
        storage: {
          getItem: (name) => {
            const str = sessionStorage.getItem(name);
            if (!str) return null;
            const { state } = JSON.parse(str) as StorageValue<PickupState>;
            return {
              state: {
                ...state,
                pickups: new Map(state.pickups),
              },
            };
          },
          setItem: (name, newValue: StorageValue<PickupState>) => {
            const str = JSON.stringify({
              state: {
                ...newValue.state,
                pickups: Array.from(newValue.state.pickups.entries()),
              },
            });
            sessionStorage.setItem(name, str);
          },
          removeItem: (name) => sessionStorage.removeItem(name),
        },
      }
    )
  )
);
