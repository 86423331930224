import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SponsorshipSchema } from "../sponsorship/SponsorshipSchema";
import { createSponsorshipFromStore } from "../sponsorship/SponsorshipMapper";

export const useSponsorForm = () =>
  useForm({
    mode: "onTouched",
    shouldFocusError: true,
    resolver: yupResolver(SponsorshipSchema()),
    defaultValues: createSponsorshipFromStore(),
  });
