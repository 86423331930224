import axios from "axios";
import { CONFIG_PATH } from "../config/api";
import { ConfigurationResponse } from "../features/configuration/useConfigurationQuery";

const fetchConfig = async (): Promise<ConfigurationResponse> => {
  const response = await axios.get<ConfigurationResponse>(CONFIG_PATH, {
    timeout: 15000,
  });
  return response.data;
};

export const configApi = {
  fetchConfig,
};
