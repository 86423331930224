import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useProfileStore } from "../features/initialRegistration/profileStore";
import { useSponsorshipStore } from "../features/initialRegistration/sponsorship/sponsorshipStore";
import { useShippingStore } from "../features/delivery/shipping/stores/shippingStore";
import { usePickupStore } from "../features/delivery/pickup/stores/pickupStore";
import { useDeliveryStore } from "../features/delivery/stores/deliveryStore";
import { useRegistrationStore } from "../features/registration/registrationStore";
import { isAdobeTagEnabled } from "./adobeTag";

type WindowWithDataLayer = Window & {
  // eslint-disable-next-line
  adobeDataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const getCurrentDateFormatted = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

const getMemberpackCurrency = () => {
  const { chosenMemberpack: shippingMemberpack } = useShippingStore.getState();
  const { chosenMemberpack: pickupMemberpack } = usePickupStore.getState();
  const { isShipping } = useDeliveryStore.getState();
  const chosenMemberpack = isShipping() ? shippingMemberpack : pickupMemberpack;
  return chosenMemberpack?.currencySymbol ?? "";
};

const sharedPreviousUrlRef = { current: null as string | null };

const useSharedPreviousUrlRef = () => {
  return sharedPreviousUrlRef;
};

export const useAdobeTagPageLoadEvent = () => {
  const location = useLocation();
  const { country, memberId } = useProfileStore.getState();
  const { registrationFinished } = useRegistrationStore.getState();
  const { confirmedSponsorId } = useSponsorshipStore.getState();
  const previousUrlRef = useSharedPreviousUrlRef();

  useEffect(() => {
    pushPageLoadEvent();
  }, [location.pathname, country]);

  const pushPageLoadEvent = () => {
    if (
      previousUrlRef.current === location.pathname ||
      !country ||
      !isAdobeTagEnabled()
    ) {
      return;
    }

    const page = location.pathname.split("/").pop();

    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "pageLoadCompleted",
      page: {
        pageInfo: {
          pageName: `hnoa:${page}`,
          globalPageTitle: page,
          localizedPageTitle: page,
          previousPage: previousUrlRef.current
            ? `myhl:${previousUrlRef.current.split("/").pop()}`
            : "",
          previousURL: previousUrlRef.current
            ? `https:/joinherbalife.online${previousUrlRef.current}`
            : "",
        },
      },
      site: {
        siteInfo: {
          environment: import.meta.env.VITE_ENVIRONMENT_NAME,
        },
        attributes: {
          countryCode: country.split("-")[1],
          languageCode: country.split("-")[0],
          locale: country,
          currencyCode: getMemberpackCurrency(),
        },
      },
      user: {
        userInfo: {
          userType: "DS",
          id: registrationFinished ? memberId : "",
          ssoProfileId: "",
          userSponsorId: confirmedSponsorId,
          userApplicationDate: getCurrentDateFormatted(),
        },
      },
      adobeEvents: `OA${page}`,
    });

    previousUrlRef.current = location.pathname;
    console.info("Adobe Tag Page Load Event Fired - ", location.pathname);
  };
};
