import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { PinSchema } from "../schemas/PinSchema";

export const usePinForm = () => {
  const pinForm = useForm({
    mode: "all",
    resolver: yupResolver(PinSchema()),
    defaultValues: {
      pin: ""
    }
  });
  const {
    formState: { isValid }
  } = pinForm;
  return { pinForm, isPinValid: isValid };
};
