/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FieldValues, get } from "react-hook-form";
import { useTinsStore } from "./tinsStore";
import { registrationNumberPrefix } from "./tinsSchema";

const getRegistrationNumbersRequest = (personalInfoValues: FieldValues) =>
  Object.entries(personalInfoValues)
    .filter(
      ([key, registrationDocument]) =>
        key.includes("registrationNumber") && registrationDocument.documentValue
    )
    .map(([, registrationNumber]) => {
      const regNumber = registrationNumber as {
        additional?: boolean;
        documentValue?: string;
        issueDate?: string;
        expiryDate?: string;
      };
      return {
        additional: !!regNumber?.additional,
        value: regNumber?.documentValue?.trim() || "",
        issueDate: regNumber?.issueDate,
        expiryDate: regNumber?.expiryDate,
      };
    });

export const createTinsFromForm = (personalInfoValues: FieldValues) => {
  return {
    isResident: !!get(personalInfoValues, "isResident"),
    registrationNumbers: Object.entries(personalInfoValues)
      .filter(
        ([key]) =>
          key.includes("registrationNumber") && !key.includes("attachment")
      )
      .map(([name, registrationNumber]) => {
        return {
          index: Number(name.slice(-1)),
          documentType: registrationNumber.documentType,
          documentValue: registrationNumber.documentValue,
          issueDate: registrationNumber.issueDate,
          expiryDate: registrationNumber.expiryDate,
          additional: registrationNumber.additional,
        };
      }),
  };
};

export const tinsFromStore = () => {
  const registrationNumbers = Object.fromEntries(
    useTinsStore.getState().registrationNumbers.map((registrationNumber) => [
      `${registrationNumberPrefix(!!useTinsStore.getState().isResident)}${
        registrationNumber.index
      }`,
      {
        documentType: registrationNumber.documentType,
        documentValue: registrationNumber.documentValue,
        issueDate: registrationNumber.issueDate,
        expiryDate: registrationNumber.expiryDate,
        additional: registrationNumber.additional,
      },
    ])
  );
  return {
    isResident: useTinsStore.getState().isResident,
    ...registrationNumbers,
  };
};

export const createRegistrationNumbersRequestFromForm = (
  personalInfoValues: FieldValues
) => {
  return {
    isResident: !!get(personalInfoValues, "isResident"),
    registrationNumbers: getRegistrationNumbersRequest(personalInfoValues),
  };
};
