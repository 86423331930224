import { StorageValue } from "zustand/middleware";

export interface StateWithSet {
  [key: string]: Set<string>;
}

export const SetStorage = <T>(nameOfSet: string) => {
  return {
    getItem: (name: string) => {
      const str = sessionStorage.getItem(name);
      const storeJson = str !== null ? str : "";

      const store = JSON.parse(storeJson) as StorageValue<T>;

      return {
        state: {
          ...store.state,
          [nameOfSet]: new Set((store.state as StateWithSet)[nameOfSet]),
        },
      };
    },
    setItem: (name: string, newValue: StorageValue<T>) => {
      const store = JSON.stringify({
        state: {
          ...newValue.state,
          [nameOfSet]: Array.from(
            (newValue.state as StateWithSet)[nameOfSet].values()
          ),
        },
      });
      sessionStorage.setItem(name, store);
    },
    removeItem: (name: string) => sessionStorage.removeItem(name),
  };
};
