import axios from "axios";
import { HMP_OWNED_READY_PATH } from "../config/api";

const hmpOwnedReady = async () => {
  return axios.post(HMP_OWNED_READY_PATH);
};

export const memberApi = {
  hmpOwnedReady,
};
