import { isArrayEqual } from "../utils/arrays";
import { MemberpackObtainingMethod } from "../features/configuration/useConfigurationQuery";
import { useHmpOwnershipStore } from "../features/personal-info/hmpOwnership/hmpOwnershipStore";

export const supportedMemberpackObtainingMethods = () =>
  useHmpOwnershipStore.getState().supportedMemberpackObtainingMethods;

export const isPaymentAndOnlineCodesLocale = () =>
  isArrayEqual<MemberpackObtainingMethod>(
    supportedMemberpackObtainingMethods(),
    [
      MemberpackObtainingMethod.MEMBERPACK_PURCHASE_VIA_OA,
      MemberpackObtainingMethod.MEMBERPACK_OBTAINED_OUTSIDE_OA,
    ]
  );

export const isOnlineCodesOnlyLocale = () =>
  isArrayEqual<MemberpackObtainingMethod>(
    supportedMemberpackObtainingMethods(),
    [MemberpackObtainingMethod.MEMBERPACK_OBTAINED_OUTSIDE_OA]
  );

export const isFreeSignupLocale = () =>
  isArrayEqual<MemberpackObtainingMethod>(
    supportedMemberpackObtainingMethods(),
    [MemberpackObtainingMethod.MEMBERPACK_NOT_REQUIRED]
  );
export const isPaymentSelected = () =>
  useHmpOwnershipStore.getState().memberpackObtainingMethod ===
  MemberpackObtainingMethod.MEMBERPACK_PURCHASE_VIA_OA;
