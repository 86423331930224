import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createHomeAddressFromForm } from "./homeAddressMapper";
import { WithTypeContaining } from "../../../types/utilityTypes";

export type HomeAddressState = {
  province: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
};

type HomeAddressAction = {
  setHomeAddress: (
    personalInfoValues: WithTypeContaining<HomeAddressState>
  ) => void;
  reset: () => void;
};

const initialState: HomeAddressState = {
  province: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  postalCode: "",
};

export const useHomeAddressStore = create<
  HomeAddressState & HomeAddressAction
>()(
  devtools(
    persist(
      (set) => ({
        province: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        setHomeAddress: (personalInfoValues) => {
          set(createHomeAddressFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "home-address-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
