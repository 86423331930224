import { UNEXPECTED_EXCEPTION } from "../../../api/shared";

export type ResponseError = {
  message?: string;
  response?: {
    status: number;
    data?: {
      errorCode?: string;
      translationKey?: string;
    };
  };
};

export const getResponseErrorKey = (error: ResponseError) => {
  return error?.response?.data?.translationKey;
};

export const getOnlySpecificErrorKey = (error: ResponseError) => {
  const errorKey = getResponseErrorKey(error);
  return errorKey === UNEXPECTED_EXCEPTION ? undefined : errorKey;
};

export const errorWithCode = (error: ResponseError) => {
  return {
    message: `${getOnlySpecificErrorKey(error)}`,
  };
};
