import React, { useEffect } from "react";

import { Logo } from "../../../components/common/Logo";
import { Footer } from "../../../components/common/Footer";
import "./CookiePolicy.scss";
import { displayCookieBanner } from "../displayCookieBanner";
import { Loader } from "../../../components/common/Loader/Loader";

export const CookiePolicyPage = () => {
  useEffect(() => {
    const country = localStorage.getItem("oa-locale") || "";
    displayCookieBanner(country);
  }, []);

  return (
    <div className="module-wrapper">
      <Logo />
      <section className="cookie-policy-container white-container">
        {/* Container in which the privacy notice will be rendered */}
        <div
          id="otnotice-0bb18aa9-7058-4965-b306-fe14a796021e"
          className="otnotice"
        >
          <div className="data-loader">
            <Loader />
          </div>
        </div>
      </section>
      <Footer hideVersion />
    </div>
  );
};
