import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { PaymentStatus } from "../../types/PaymentStatus";

export enum CashierStatus {
  ERROR = "ERROR",
  READY = "READY",
  STARTED = "STARTED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  RETRY = "RETRY",
}

enum CashierMode {
  HIDDEN = "HIDDEN",
  PAY = "PAY",
  RETRY = "RETRY",
  RECEIPT = "RECEIPT",
}

export type CashierConfiguration = {
  piqAmount: number;
  piqEnvironment: string;
  piqMerchantId: string;
  piqSessionId: string;
  piqUserId: string;
};

type PaymentIQState = {
  cashierStatus: CashierStatus;
  txRefId: string;
  cashierConfiguration: CashierConfiguration;
};

type PaymentIQAction = {
  startCashier: () => void;
  setCashierConfiguration: (cashierConfiguration: CashierConfiguration) => void;
  setCashierStatusBasedOn: (paymentStatus: PaymentStatus) => void;
  getCashierMode: () => CashierMode;
  setCashierFailed: () => void;
  setCashierSuccessful: () => void;
  setTxRefId: (txRefId: string) => void;
  setCashierReady: () => void;
  reset: () => void;
};

const initialState = {
  cashierStatus: CashierStatus.READY,
  txRefId: "",
  cashierConfiguration: <CashierConfiguration>{},
};

export const usePaymentIQStore = create<PaymentIQState & PaymentIQAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        startCashier: () => {
          set({
            cashierStatus:
              get().cashierStatus === CashierStatus.READY
                ? CashierStatus.STARTED
                : CashierStatus.RETRY,
          });
        },
        setCashierConfiguration: (cashierConfiguration) => {
          set({
            cashierConfiguration,
          });
        },
        setCashierStatusBasedOn: (paymentStatus) => {
          switch (paymentStatus) {
            case PaymentStatus.SUCCESSFUL:
              set({ cashierStatus: CashierStatus.SUCCESSFUL });
              break;
            case PaymentStatus.CANCELLED:
              set({ cashierStatus: CashierStatus.FAILED });
              break;
            default:
              set({ cashierStatus: CashierStatus.READY });
          }
        },
        getCashierMode: () => {
          switch (get().cashierStatus) {
            case CashierStatus.STARTED:
              return CashierMode.PAY;
            case CashierStatus.SUCCESSFUL:
            case CashierStatus.FAILED:
              return CashierMode.RECEIPT;
            case CashierStatus.RETRY:
              return CashierMode.RETRY;
            default:
              return CashierMode.HIDDEN;
          }
        },
        setCashierSuccessful: () => {
          set({
            cashierStatus: CashierStatus.SUCCESSFUL,
          });
        },
        setCashierFailed: () => {
          set({
            cashierStatus: CashierStatus.FAILED,
          });
        },
        setCashierReady: () => {
          set({
            cashierStatus: CashierStatus.READY,
          });
        },
        setTxRefId: (txRefId) => {
          set({
            txRefId,
          });
        },
        reset: () => set(initialState),
      }),
      {
        name: "paymentIQ-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
