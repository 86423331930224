type JWT = { sub: string; registrationId: string; iat: number; exp: number };

export function parseJwt(token: string): JWT {
  if (!token) {
    return { sub: "", registrationId: "", iat: 0, exp: 0 };
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return <JWT>JSON.parse(jsonPayload);
}
