import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useNavigate } from "react-router-dom";
import { useMeasure } from "react-use";
import { useTranslation } from "react-i18next";
import { getSteps, getUrlForStep } from "./steps";
import { useRegistrationStore } from "../registration/registrationStore";

type Props = {
  activeStep: number;
};
export const BreadcrumbStepper = ({ activeStep }: Props) => {
  const { t } = useTranslation();
  const [readOnlyMode, registrationFinished] = useRegistrationStore((state) => [
    state.readOnlyMode,
    state.registrationFinished,
  ]);
  const navigate = useNavigate();
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const steps = getSteps();

  const [stepperRef, { width: stepperWidth }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    let completedIndex = activeStep - 1;
    if (readOnlyMode) {
      completedIndex = steps.length - 2;
    }
    setCompleted(Array.from({ length: completedIndex }).map(() => true));
  }, [activeStep]);

  const handleStep = (step: number) => () => navigate(getUrlForStep(step));

  const isStepDisabled = (step: number) => {
    if (registrationFinished) {
      return false;
    }

    return (
      step >= activeStep - 1 && !(readOnlyMode && step !== steps.length - 1)
    );
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "4rem" }} ref={stepperRef}>
      <Stepper
        activeStep={activeStep - 1}
        alternativeLabel
        style={{ width: "100%" }}
        sx={{
          ".MuiStepConnector-root span::before": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        {steps.map((label, index) => (
          <Step
            role="progressbar"
            aria-label={label}
            key={label}
            completed={
              registrationFinished ||
              (completed[index] && activeStep - 1 !== index)
            }
            sx={{
              "& .Mui-active": {
                "&.MuiStepIcon-root": {
                  color: "#266431",
                },
              },
              "& .Mui-completed": {
                "&.MuiStepIcon-root": {
                  color: "#266431",
                },
              },
            }}
          >
            <StepButton
              color="inherit"
              disabled={isStepDisabled(index)}
              onClick={handleStep(index)}
            >
              <StepLabel style={{ wordBreak: "break-word" }}>
                {stepperWidth > 500 && t(label)}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
