import { IS_PROD } from "./constants";

export const isAdobeTagEnabled = () =>
  import.meta.env.VITE_ADOBE_TAG_ENABLED === "true";

export const initAdobeTag = () => {
  const src = IS_PROD
    ? "assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-1901af4e408c.min.js"
    : "assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-353880831a1f-development.min.js";
  const hasProdScriptAlready = Boolean(
    Array.from(document.getElementsByTagName("script")).find((script) =>
      script.src.includes(src)
    )
  );
  if (!hasProdScriptAlready && isAdobeTagEnabled()) {
    const adobeTagScriptElement = document.createElement("script");
    adobeTagScriptElement.type = "text/javascript";
    adobeTagScriptElement.async = true;
    adobeTagScriptElement.src = `https://${src}`;
    document.getElementsByTagName("head")[0].appendChild(adobeTagScriptElement);
  }
};
