import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { logEvent } from "../../utils/firebase";
import { SetStorage } from "../../stores/storage";

export const PERSONAL_INFO_VISIT_EVENT = "Personal Info Page Visited";
export const GUARANTEES_VISIT_EVENT = "Guarantees Page Visited";
export const AGREEMENT_VISIT_EVENT = "Agreement Page Visited";
export const PAYMENT_VISIT_EVENT = "Payment Page Visited";
export const CONFIRMATION_VISIT_EVENT = "Confirmation Page Visited";
export const NEW_CONFIRMATION_VISIT_EVENT = "New Confirmation Page Visited";
export const PAYMENT_STARTED_EVENT = "Payment started";
export const REGISTRATION_DONE_EVENT = "Registration finished";
export const REGISTRATION_DONE_BY_PAYMENT_EVENT =
  "Registration finished by payment";
export const REGISTRATION_DONE_BY_HMP_CODE_EVENT =
  "Registration finished by HMP code";
export const SLOW_PAYMENT_EVENT = "Payment took too long";
export const PAYMENT_STATUS_VISIT_EVENT = "Payment status page visited";
export const USER_SESSION_EXPIRED_EVENT = "User session expired";
export const OLD_JOIN_HERBALIFE_ONLINE_EVENT = "User visited previous  OA page";

type FirebaseState = {
  loggedFirebaseEvents: Set<string>;
};

type FirebaseAction = {
  sendFirebaseEvent: (event: string, parameters: object) => void;
};

export const useFirebaseStore = create<FirebaseState & FirebaseAction>()(
  devtools(
    persist(
      (set, get) => ({
        loggedFirebaseEvents: new Set<string>(),
        sendFirebaseEvent: (event, parameters) => {
          if (!get().loggedFirebaseEvents.has(event)) {
            logEvent(event, parameters);
            set(
              (state) => ({
                loggedFirebaseEvents: new Set(state.loggedFirebaseEvents).add(
                  event
                ),
              }),
              false,
              {
                type: "event/addFirebaseEvent",
                event,
              }
            );
          }
        },
      }),
      {
        name: "firebase-storage",
        storage: SetStorage<FirebaseState>("loggedFirebaseEvents"),
      }
    )
  )
);
