import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../../components/common/Footer";
import { ShowValidSection } from "../../components/common/ShowValidSection";
import { GoldStandard } from "../../components/GoldStandard/GoldStandard";
import {
  GUARANTEES_VISIT_EVENT,
  useFirebaseStore,
} from "../../features/firebase/firebaseStore";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useRegistrationStore } from "../../features/registration/registrationStore";
import { BreadcrumbStepper } from "../../features/breadcrumb/BreadcrumbStepper";

const GoldStandardPage = () => {
  const { t } = useTranslation();
  const country = useProfileStore((state) => state.country);
  const sendFirebaseEvent = useFirebaseStore(
    (state) => state.sendFirebaseEvent
  );
  const readOnlyRegistration = useRegistrationStore(
    (state) => state.readOnlyMode
  );

  useEffect(() => {
    sendFirebaseEvent(GUARANTEES_VISIT_EVENT, {
      idNumber: useProfileStore.getState().memberId,
      country,
    });
  });

  return (
    <div className="module-wrapper">
      <Logo />
      <BreadcrumbStepper activeStep={3} />
      <h3 className="online-profile-header" data-testid="page-header">
        {t("GOLD_STANDARD")}
      </h3>
      <ShowValidSection isDataSubmitted={readOnlyRegistration}>
        <GoldStandard disabled={readOnlyRegistration} />
      </ShowValidSection>
      <Footer />
    </div>
  );
};

export default GoldStandardPage;
