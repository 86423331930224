import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";

type AdditionalOptions = {
  allowMultiLoading: boolean;
  overrideMimeType: boolean;
};

const backendOptions: HttpBackendOptions & AdditionalOptions = {
  loadPath: `/translations/{{lng}}`,
  allowMultiLoading: false,
  parse(data: string) {
    return JSON.parse(data) as Record<string, object>;
  },

  crossDomain: false,
  withCredentials: false,
  overrideMimeType: false,

  requestOptions: {
    mode: "cors",
    credentials: "same-origin",
    cache: "default",
  },
  reloadInterval: false,
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init<HttpBackendOptions>({
    fallbackLng: "en",
    load: "currentOnly",
    keySeparator: false,
    backend: backendOptions,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
