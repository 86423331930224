import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ReferralInformation.module.scss";

type ReferrerInformationProps = {
  referrerFirstName: string;
  referrerLastName: string;
};

export const ReferrerInformation = ({
  referrerFirstName,
  referrerLastName,
}: ReferrerInformationProps) => {
  const { t } = useTranslation();
  if (referrerFirstName && referrerLastName) {
    return (
      <div className={styles.referralData} data-testid="referrer-data">
        <span>{t("REFERRER_FOUND")}</span>
        <h2>{[referrerFirstName, referrerLastName].join(" ")}</h2>
      </div>
    );
  }
  return null;
};
