import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { MobileSchema } from "../schemas/MobileSchema";
import { useProfileStore } from "../profileStore";
import { useDefaultPhoneCountryCode } from "../useDefaultPhoneCountryCode";

export const useMobileForm = () => {
  const customerForm = useForm({
    mode: "onTouched",
    resolver: yupResolver(MobileSchema()),
    defaultValues: {
      countryCode: useProfileStore.getState().countryCode,
      phoneNumber: useProfileStore.getState().phoneNumber,
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
    setValue,
  } = customerForm;

  const useDefaultPhoneCountryCodeIfDefined = () =>
    useDefaultPhoneCountryCode(setValue);

  return {
    mobileForm: customerForm,
    isValid,
    handleSubmit,
    setValue,
    useDefaultPhoneCountryCodeIfDefined,
  };
};
