import { MixedSchema } from "yup";

const FILE_EXTENSION_REGEXP = /\.(jpg|jpeg|png|pdf|bmp)$/i;
const MAX_FILE_SIZE_IN_BYTES = 4000000;
export const UPLOAD_FILE_INVALID_EXTENSION = "UPLOAD_FILE_INVALID_EXTENSION";
export const UPLOAD_FILE_SIZE_EXCEEDED = "UPLOAD_FILE_SIZE_EXCEEDED";
export function attachmentExtensionValidation(this: MixedSchema) {
  return this.test("attachment-extension", function validate(value) {
    const { path, createError } = this;

    const file = value as File;
    if (file && file.name && !FILE_EXTENSION_REGEXP.test(file.name)) {
      return createError({
        path,
        message: UPLOAD_FILE_INVALID_EXTENSION,
      });
    }
    return true;
  });
}

export function attachmentSizeValidation(this: MixedSchema) {
  return this.test("attachment-size", function validate(value) {
    const { path, createError } = this;

    const file = value as File;
    if (file && file?.size > MAX_FILE_SIZE_IN_BYTES) {
      return createError({
        path,
        message: UPLOAD_FILE_SIZE_EXCEEDED,
      });
    }
    return true;
  });
}
