import * as React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface CustomInfoProps {
  className?: string;
  info: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
  requiredAsterisk?: boolean;
}

export const CustomInfo = ({
  className,
  info,
  onClick,
  onKeyPress,
  requiredAsterisk,
}: CustomInfoProps) => (
  <section
    className={classNames(styles.customInfo, className)}
    data-testid="custom-info"
    onClick={onClick}
    onKeyDown={onKeyPress}
    role="button"
    tabIndex={0}
  >
    <span>{info}</span>
    {requiredAsterisk && <span className="asterisk"> *</span>}
  </section>
);
