import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { SponsorshipResponse } from "./mutations/confirmSponsorshipMutation";

interface SponsorshipState {
  confirmedSponsorId: string;
}

interface SponsorState {
  sponsorId: string;
  sponsorNameFirstLetters: string;
  sponsorFirstName: string;
  sponsorLastName: string;
  sponsorAlreadyConfirmedInHerbalifeSystem: boolean;
}

interface ReferrerState {
  referrerFirstName: string;
  referrerLastName: string;
}

interface SponsorshipAction {
  setSponsorFormData: (
    sponsorId: string,
    sponsorNameFirstLetters: string
  ) => void;
  confirmSponsorship: (sponsorshipResponse: SponsorshipResponse) => void;
  setConfirmedSponsor: (
    sponsorId: string | undefined,
    sponsorFirstName: string | undefined,
    sponsorLastName: string | undefined
  ) => void;
  isSponsorConfirmed: () => boolean;
  clearConfirmation: () => void;
  blockSponsorChange: () => void;
  isSponsorshipEditable: () => boolean;
  reset: () => void;
}

const initialState: SponsorshipState & SponsorState & ReferrerState = {
  confirmedSponsorId: "",
  sponsorId: "",
  sponsorNameFirstLetters: "",
  sponsorFirstName: "",
  sponsorLastName: "",
  referrerFirstName: "",
  referrerLastName: "",
  sponsorAlreadyConfirmedInHerbalifeSystem: false,
};

export const useSponsorshipStore = create<
  SponsorshipState & SponsorState & ReferrerState & SponsorshipAction
>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          ...initialState,
          setSponsorFormData: (sponsorId, sponsorNameFirstLetters) => {
            set({ sponsorId, sponsorNameFirstLetters });
          },
          confirmSponsorship: (sponsorshipResponse) => {
            set({
              confirmedSponsorId: sponsorshipResponse?.sponsor?.id,
              sponsorFirstName: sponsorshipResponse?.sponsor?.firstName,
              sponsorLastName: sponsorshipResponse?.sponsor?.lastName,
              referrerFirstName: sponsorshipResponse?.referrer?.firstName,
              referrerLastName: sponsorshipResponse?.referrer?.lastName,
            });
          },
          setConfirmedSponsor: (
            sponsorId,
            sponsorFirstName,
            sponsorLastName
          ) => {
            if (sponsorId && sponsorFirstName && sponsorLastName) {
              set({
                confirmedSponsorId: sponsorId,
                sponsorFirstName,
                sponsorLastName,
              });
              get().blockSponsorChange();
            }
          },
          isSponsorConfirmed: () => {
            return !!get().confirmedSponsorId;
          },
          clearConfirmation: () =>
            set({
              confirmedSponsorId: "",
              sponsorFirstName: "",
              sponsorLastName: "",
              referrerFirstName: "",
              referrerLastName: "",
            }),
          blockSponsorChange: () => {
            set({ sponsorAlreadyConfirmedInHerbalifeSystem: true });
          },
          isSponsorshipEditable: () => {
            return !get().sponsorAlreadyConfirmedInHerbalifeSystem;
          },
          reset: () => set(initialState),
        }),
        {
          name: "sponsorship-storage",
          storage: createJSONStorage(() => sessionStorage),
        }
      )
    )
  )
);
