import React, { useTransition } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./MaintenancePage.module.scss";
import MaintenanceModeImage from "../../assets/images/maintenance_mode.png";
import { Button } from "../../components/common/Button";
import { Spinner } from "../../components/common/Spinner";

const MaintenancePage = () => {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  return (
    <div className={styles.maintenanceModeContainer}>
      <Spinner isShowing={isPending} />
      <img alt="maintenance mode" src={MaintenanceModeImage} />
      <h3>Application is under maintenance now. Please try again later.</h3>
      <Button
        type="button"
        className={styles.tryAgainButton}
        onClick={() => startTransition(() => navigate("/register"))}
      >
        Try Again
      </Button>
    </div>
  );
};

export default MaintenancePage;
