import React from "react";
import { useTranslation } from "react-i18next";

import { FallbackProps } from "react-error-boundary";
import { Button } from "../../components/common/Button";
import styles from "./ErrorPage.module.scss";

const ErrorPage = (props: FallbackProps) => {
  const { resetErrorBoundary } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.errorPageWrapper} role="alert">
      <div className={styles.textContent}>
        <span>{t("SOMETHING_WENT_WRONG")}</span>
        <Button onClick={resetErrorBoundary}>{t("START_AGAIN")}</Button>
        <span>{t("CONTACT_SUPPORT")}</span>
      </div>
    </div>
  );
};

export default ErrorPage;
