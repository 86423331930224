import axios from "axios";

import {
  PHONE_NUMBER_VERIFICATION_PATH,
  SMS_PIN_VERIFICATION_PATH,
} from "../config/api";
import { VerifyPinRequest } from "../features/initialRegistration/mutations/verifyPinMutation";

const verifyPhoneNumber = async (
  countryCode: string,
  subscriberNumber: string
) => {
  return axios.post(PHONE_NUMBER_VERIFICATION_PATH, {
    countryCode,
    subscriberNumber,
  });
};

const verifyPinCode = async (request: VerifyPinRequest) => {
  const response = await axios.post<void>(SMS_PIN_VERIFICATION_PATH, request);
  return response.data;
};

export const smsVerification = {
  verifyPhoneNumber,
  verifyPinCode,
};
