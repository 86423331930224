const EMAIL_PATTERN = /^([a-z0-9_.-]+)@([\da-z-]+)\.([a-z.]{2,8})$/i;

const anonymizeString = (word: string) => {
  if (!word) {
    return "";
  }
  if (word.length < 3) {
    return "***";
  }
  if (word.length < 6) {
    // one character at beginning and at end
    return word.replace(/(.)(.*)(.)/, "$1***$3");
  }
  // two characters at beginning and at the end
  return word.replace(/(.{2})(.*)(.{2})/, "$1***$3");
};

export const anonymize = (word: string) => {
  if (EMAIL_PATTERN.test(word)) {
    // 3 characters at beginning + @ + 1 to 3 characters for domain + . with ending
    return word.replace(
      /(\w{3})[\w.-]+@(\w{1,3})[\w.-]+\.(.*)/,
      "$1***@$2***.$3"
    );
  }
  return anonymizeString(word);
};
