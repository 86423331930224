import * as React from "react";
import styles from "./styles.module.scss";

export interface Props {
  message: string;
  showOn?: boolean;
  center?: boolean;
}

export const Error = ({ message, showOn = true, center }: Props) =>
  showOn && message ? (
    <span
      role="alert"
      className={`${styles.error} ${center ? styles.center : ""}`}
    >
      {message}
    </span>
  ) : null;
