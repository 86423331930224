import yup from "../../yup/yupInterface";

export const PinSchema = () => {
  return yup.object({
    pin: yup.string().test("pin-code-validation", function validate(value) {
      const { path, createError } = this;
      const isPinValid = !Number.isNaN(Number(value));

      return !isPinValid ? createError({ path, message: "PIN_INVALID" }) : true;
    })
  });
};
