import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type RegistrationState = {
  readOnlyMode: boolean;
  registrationFinished: boolean;
};

type RegistrationAction = {
  blockRegistrationForm: () => void;
  finishRegistration: () => void;
  reset: () => void;
};

export const useRegistrationStore = create<
  RegistrationState & RegistrationAction
>()(
  devtools(
    persist(
      (set) => ({
        readOnlyMode: false,
        registrationFinished: false,
        blockRegistrationForm: () => set({ readOnlyMode: true }),
        finishRegistration: () => set({ registrationFinished: true }),
        reset: () => set({ readOnlyMode: false, registrationFinished: false }),
      }),
      {
        name: "registration-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
