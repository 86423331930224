export enum PaymentStatus {
  NOT_STARTED = "NOT_STARTED",
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESSFUL = "SUCCESSFUL",
  CANCELLED = "CANCELLED",
}

export const paymentStatusAndQueryParamsMapping = new Map<string, string>([
  ["started", "IN_PROGRESS"],
  ["failed", "CANCELLED"],
  ["succeded", "SUCCESSFUL"],
]);

export const fromQueryParam = (
  paymentStatus: string | null
): PaymentStatus | undefined =>
  paymentStatus && paymentStatusAndQueryParamsMapping.has(paymentStatus)
    ? (paymentStatusAndQueryParamsMapping.get(paymentStatus) as PaymentStatus)
    : undefined;
