import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../../components/common/Footer";
import { Spinner } from "../../components/common/Spinner";
import { MembershipAgreement as MembershipAgreementComponent } from "../../components/MembershipAgreement/MembershipAgreement";
import { ShowValidSection } from "../../components/common/ShowValidSection";
import { Button } from "../../components/common/Button";
import { Headline } from "../../components/Headline";
import { scrollToError } from "../../utils/scrollToError";
import { ErrorMessage } from "../../components/ErrorMessage";
import {
  MainContainer,
  MissingConfirmationContainer,
  ConfirmationButtonContainer,
  ModuleContainer,
} from "../../components/Containers";
import { UNEXPECTED_EXCEPTION } from "../../api/shared";
import { BreadcrumbStepper } from "../../features/breadcrumb/BreadcrumbStepper";

interface MembershipAgreementProps {
  isError: boolean;
  isDataSubmitted: boolean;
  loading?: boolean;
  onAccept: () => void;
}

export const MembershipAgreement = ({
  isError,
  isDataSubmitted,
  loading,
  onAccept,
}: MembershipAgreementProps) => {
  const { t } = useTranslation();
  const [agreeAgreement, setAgreeAgreement] = React.useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = React.useState<boolean>(false);
  const nodeRef = React.useRef(null);
  const sendMemberData = () => {
    if (!agreeAgreement) {
      setShowErrorMsg(true);
    } else {
      onAccept();
      scrollToError();
    }
  };

  const confirmationCheckboxHandler = () => {
    setAgreeAgreement(!agreeAgreement);
    if (showErrorMsg) {
      setShowErrorMsg(!showErrorMsg);
    }
  };

  return (
    <ModuleContainer data-testid="membership-agreement-container">
      {loading && <Spinner />}
      <Logo />
      <BreadcrumbStepper activeStep={4} />
      <Headline>{t("AGREEMENT")}</Headline>
      <ShowValidSection isDataSubmitted={isDataSubmitted}>
        <MainContainer>
          <MembershipAgreementComponent
            agreeAgreement={agreeAgreement}
            confirmationOnChange={confirmationCheckboxHandler}
            disabled={isDataSubmitted}
          />
          <ConfirmationButtonContainer>
            <MissingConfirmationContainer>
              <CSSTransition
                in={showErrorMsg && !agreeAgreement}
                classNames="expandable-section"
                nodeRef={nodeRef}
                timeout={300}
                unmountOnExit
              >
                <ErrorMessage
                  data-testid="error-missing-confirmation"
                  className="side-paddings"
                >
                  {t("AGREEMENT_ERROR")}
                </ErrorMessage>
              </CSSTransition>
            </MissingConfirmationContainer>
            {isError && (
              <ErrorMessage className="error" data-testid="error-no-code">
                {t(UNEXPECTED_EXCEPTION)}
              </ErrorMessage>
            )}
            <Button
              id="agreement-confirmation-button"
              hasDisabledStyle={!agreeAgreement}
              onClick={sendMemberData}
            >
              {t("CONFIRM_CONTINUE")}
            </Button>
          </ConfirmationButtonContainer>
        </MainContainer>
      </ShowValidSection>
      <Footer />
    </ModuleContainer>
  );
};
