import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { VALIDATION_RULES } from "../../../config/constants";
import { useProfileStore } from "../../../features/initialRegistration/profileStore";
import { useConfigurationStore } from "../../../features/configuration/configurationStore";
import styles from "./Footer.module.scss";

type FooterProps = {
  hideVersion?: boolean;
};

type Logo = {
  key: string;
  alt: string;
  src: string;
  urlLink?: string;
};

export const Footer = ({ hideVersion }: FooterProps) => {
  const { t } = useTranslation();
  const version = useConfigurationStore((state) => state.version);

  const country = useProfileStore((state) => state.country);
  const footerLogos = VALIDATION_RULES[country]?.footerLogos;
  const showCookiePrivacyLink =
    VALIDATION_RULES[country]?.showLinkToCookiePolicy;

  return (
    <div className={styles.footer} data-testid="footer">
      <a target="_blank" rel="noopener noreferrer" href={t("TERMS_LINK")}>
        {t("TERMS")}
      </a>
      <a target="_blank" rel="noopener noreferrer" href={t("PRIVACY_LINK")}>
        {t("PRIVACY")}
      </a>
      {showCookiePrivacyLink && (
        <Link to="/cookiepolicy" target="_blank">
          {t("COOKIE_POLICY")}
        </Link>
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={t("RULES_OF_CONDUCT_LINK")}
      >
        {t("RULES_OF_CONDUCT")}
      </a>
      {footerLogos && (
        <div className={styles.footerLogosWrapper}>
          {(footerLogos as [Logo]).map((logo: Logo) =>
            logo.urlLink ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={logo.urlLink}
                key={logo.alt}
              >
                <img key={logo.src} alt={logo.alt} src={logo.src} />
              </a>
            ) : (
              <img key={logo.src} alt={logo.alt} src={logo.src} />
            )
          )}
        </div>
      )}
      <span>{t("COPYRIGHT_NOTICE")}</span>
      {hideVersion ? null : (
        <span className={styles.version} data-testid="version">
          {version}
        </span>
      )}
    </div>
  );
};
