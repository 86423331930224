import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import styles from "../../../pages/Landing/LandingPage.module.scss";
import { useSponsorshipStore } from "./sponsorshipStore";
import { useRegistrationStore } from "../../registration/registrationStore";
import MandatoryFieldsAnnotation from "../../../components/MandatoryFieldsAnnotation/MandatoryFieldsAnnotation";
import { ReferrerInformation } from "../../../containers/SponsorInformation/ReferrerInformation";

type Props = {
  editSponsor: () => void;
};
export const SelectedSponsor = ({ editSponsor }: Props) => {
  const { t } = useTranslation();
  const [
    sponsorFirstName,
    sponsorLastName,
    referrerFirstName,
    referrerLastName,
  ] = useSponsorshipStore((state) => [
    state.sponsorFirstName,
    state.sponsorLastName,
    state.referrerFirstName,
    state.referrerLastName,
  ]);
  const [isSponsorConfirmed, isSponsorshipEditable] = useSponsorshipStore(
    (state) => [state.isSponsorConfirmed, state.isSponsorshipEditable]
  );
  const readOnlyRegistration = useRegistrationStore(
    (state) => state.readOnlyMode
  );

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      editSponsor();
    }
  };

  return (
    <section className={styles.entryDataWrapper} data-testid="sponsor-section">
      <MandatoryFieldsAnnotation />
      <section className={styles.editableSectionWrapper}>
        <span
          className={styles.shrinkableSponsorInfo}
          data-testid="your-sponsor-is"
        >
          {t("YOUR_SPONSOR_IS")}
        </span>
        <div className={styles.sponsorNameWithEditIcon}>
          <h2 data-testid="sponsor-names">
            {[sponsorFirstName, sponsorLastName].join(" ")}
          </h2>
          {isSponsorConfirmed() &&
            isSponsorshipEditable() &&
            !readOnlyRegistration && (
              <section
                className={styles.editIconContainer}
                onClick={editSponsor}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
              >
                <EditIcon
                  aria-label="edit"
                  data-testid="sponsor-data-edit-button"
                />
              </section>
            )}
        </div>
      </section>
      <ReferrerInformation
        referrerFirstName={referrerFirstName}
        referrerLastName={referrerLastName}
      />
      <h3 data-testid="lets-get-started">{t("LETS_STARTED")}</h3>
    </section>
  );
};
