import * as yup from "yup";
import dayjs from "dayjs";
import { isSafari } from "../../../../utils/detect";
import { convertDateFormat } from "../../../../utils/convertDateFormat";
import { TinRule } from "../../../../config/model/TinRules";
import { MixedSchema, StringSchema } from "yup";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../attachments/validations/attachmentValidations";
import { RegistrationNumber } from "../tinsStore";

export const issueDateValidation = () => {
  return yup
    .string()
    .required("REQUIRED")
    .test("issueDate-validation", function validate(value) {
      const { path, createError } = this;
      let date;
      if (isSafari) {
        date = convertDateFormat(value);
      } else {
        date = value;
      }
      if (dayjs().diff(dayjs(date), "days") < 1) {
        return createError({ path, message: "WRONG_ISSUE_DATE" });
      }
      return true;
    });
};

export const expiryDateValidation = () => {
  return yup
    .string()
    .required("REQUIRED")
    .test("expiryDate-validation", function validate(value) {
      const { path, createError } = this;
      let date;
      if (isSafari) {
        date = convertDateFormat(value);
      } else {
        date = value;
      }
      if (dayjs().diff(dayjs(date), "hours") > 0) {
        return createError({ path, message: "WRONG_EXPIRY_DATE" });
      }
      return true;
    });
};

export function documentTypeValidation(this: StringSchema, tinRule: TinRule) {
  return this.test("documentTypePatternCheck", function validate(value) {
    const { path, createError } = this;
    const { documentType } = this.parent as RegistrationNumber;

    const tinPatternMatched = tinRule.documentTypes
      ?.filter((documentTypeRule) => documentTypeRule.key === documentType)
      .some((documentTypeRule) => value?.match(documentTypeRule.pattern));

    if (!tinRule.required && !documentType) {
      return true;
    }

    return (
      tinPatternMatched ||
      createError({
        path,
        message: "INVALID_DOCUMENT_TYPE_FORMAT",
      })
    );
  });
}
