import React from "react";
import styles from "./styles.module.scss";

type Props = {
  isShowing?: boolean;
};
export const Spinner = ({ isShowing = true }: Props) => {
  if (!isShowing) {
    return null;
  }

  return (
    <section className={styles.backdrop} data-testid="backdrop">
      <div className={styles.spinner}>
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
    </section>
  );
};
