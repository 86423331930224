import yup from "../../yup/yupInterface";
import { DIGITS_AND_OR_LATIN_LETTERS_REGEX } from "../../../config/constants";

export const SponsorshipSchema = () => {
  return yup.object({
    sponsorId: yup
      .string()
      .trim()
      .required("REQUIRED")
      .min(7, "INCORRECT_SPONSOR_ID")
      .matchesPattern(
        DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        "SPONSOR_ID_INVALID_CHARACTERS"
      ),
    sponsorNameFirstLetters: yup
      .string()
      .trim()
      .required("REQUIRED")
      .min(1, "REQUIRED"),
  });
};
