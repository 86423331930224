import Cookies from "js-cookie";
import { useEffect } from "react";
import { useAuthenticationStore } from "../../authentication/authenticationStore";
import { resetAllStores } from "../resetAllStores";
import { useInitialRegistrationMutation } from "../mutations/useInitialRegistrationMutation";
import * as Sentry from "@sentry/browser";
import { parseJwt } from "../../../utils/jwt";
import { useProfileStore } from "../profileStore";

export const useInitialRegistrationData = () => {
  const OA_TOKEN_COOKIE_NAME = "OA-Token";
  const initialRegistrationMutation = useInitialRegistrationMutation();
  const [setToken, isAuthenticated] = useAuthenticationStore((state) => [
    state.setToken,
    state.isAuthenticated,
  ]);
  const country = useProfileStore((state) => state.country);

  function currentUserRefreshesPage(oaTokenCookie: string | undefined) {
    return !oaTokenCookie && isAuthenticated();
  }

  useEffect(() => {
    const oaTokenCookie = Cookies.get(OA_TOKEN_COOKIE_NAME);
    if (currentUserRefreshesPage(oaTokenCookie)) {
      return;
    }

    if (!oaTokenCookie) {
      window.location.href = import.meta.env.VITE_SSO_COUNTRY_SELECTION_LINK;
    } else {
      resetAllStores();
      setToken(oaTokenCookie);
      if (country) {
        Sentry.setTag("country", country);
      }
      Sentry.setTag("registrationId", parseJwt(oaTokenCookie).registrationId);
      initialRegistrationMutation.mutate();
      Cookies.remove(OA_TOKEN_COOKIE_NAME);
    }
  }, []);
};
