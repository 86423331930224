import {
  DEFAULT_MAXIMUM_PHONE_NUMBER_LENGTH,
  MOBILE_RULES,
} from "../config/constants";

export const determinePhoneNumberLength = (countryCodeFullName: string) => {
  const countryCode = countryCodeFullName?.replace(/[^0-9]/g, "");
  return (
    MOBILE_RULES[countryCode]?.phoneNumberMaxLength ||
    DEFAULT_MAXIMUM_PHONE_NUMBER_LENGTH - Number(countryCode?.length) ||
    DEFAULT_MAXIMUM_PHONE_NUMBER_LENGTH
  );
};
