const REQUEST_ABORTED = "CanceledError";
let abortController = new AbortController();

export const isRequestAborted = (err: Error) => {
  return err.name === REQUEST_ABORTED;
};

export const getAbortController = () => abortController;

export const abortRequest = () => {
  abortController.abort();
  abortController = new AbortController();
};
