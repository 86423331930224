import * as React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  hasDisabledStyle?: boolean;
  disabled?: boolean;
  "data-testid"?: string;
}

export const Button = ({
  className,
  hasDisabledStyle = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const css: string = classNames(styles.primary, className, {
    [styles.disabled]: hasDisabledStyle,
  });

  let testId =
    disabled || hasDisabledStyle ? "button-disabled" : "button-enabled";

  if (props["data-testid"]) {
    testId = props["data-testid"];
  }

  return (
    <button
      type="button"
      className={css}
      data-testid={testId}
      disabled={disabled}
      {...props}
    >
      {props.children}
    </button>
  );
};
