import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { StringSchema } from "yup";

export function forbiddenWordValidation(
  this: StringSchema,
  country: string,
  errorMessage: string
) {
  return this.test(
    "forbidden-words",
    errorMessage,
    (value) =>
      !getForbiddenWordsForCountry(country)?.some((forbiddenWord) =>
        value?.toLowerCase().includes(forbiddenWord)
      )
  );
}

export function patternValidation(
  this: StringSchema,
  pattern: RegExp,
  errorMessage: string
) {
  return this.test("pattern-validation", errorMessage, (value) =>
    pattern ? pattern.test(value || "") : true
  );
}
