import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const DataSubmittedInfo = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.dataSubmitted} data-testid="data-submitted">
      <span>{t("SUBMISSION_COMPLETED")}</span>
    </section>
  );
};
