export enum PaymentMethod {
  CARD = "CARD",
  PAYPAL = "PAYPAL",
  SOFORT = "SOFORT",
  UNKNOWN = "UNKNOWN",
}

export enum CardProvider {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
}
