import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface ContractState {
  contractUrl: string;
  setContractLink: (url: string) => void;
  reset: () => void;
}

export const useContractStore = create<ContractState>()(
  devtools(
    persist(
      (set) => ({
        contractUrl: "",
        setContractLink: (url) => set({ contractUrl: url }),
        reset: () => set({ contractUrl: "" }),
      }),
      {
        name: "contract-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
