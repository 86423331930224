import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./MandatoryFieldsAnnotation.module.scss";

const MandatoryFieldsAnnotation = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className={styles.mandatoryFieldsInfoWrapper}>
        <span data-testid="mandatory-fields-statement-p1">
          {t("MANDATORY_FIELDS_STATEMENT_PART1")}
        </span>
        <span>
          (<span data-testid="required-asterisk">*</span>)
        </span>
        <span data-testid="mandatory-fields-statement-p2">
          {t("MANDATORY_FIELDS_STATEMENT_PART2")}
        </span>
      </section>
      <h2
        className={styles.organizationStructureQuestion}
        data-testid="organization-structure-question"
      >
        {t("ORGANIZATION_STRUCTURE_QUESTION")}
      </h2>
    </>
  );
};

export default MandatoryFieldsAnnotation;
