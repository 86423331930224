import * as React from "react";
import styles from "./styles.module.scss";

interface MissingConfirmationContainerProps {
  children: JSX.Element | JSX.Element[] | string | string[];
}

export const MissingConfirmationContainer = ({
  children,
}: MissingConfirmationContainerProps) => (
  <section className={styles.missingConfirmationContainer}>{children}</section>
);
