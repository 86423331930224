import React, { ReactNode } from "react";
import { Navigate } from "react-router";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { useLocation } from "react-router-dom";
import { useAuthorizationStore } from "../../features/authorization/authorizationStore";
import { useAdobeTagPageLoadEvent } from "../../config/useAdobeTagPageLoadEvent";

interface Props {
  children: ReactNode | ReactNode[];
}
export const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();
  const [isUserPermittedToPath, redirectToLastPermittedPath] =
    useAuthorizationStore((state) => [
      state.isUserPermittedToPath,
      state.redirectToLastPermittedPath,
    ]);
  const isAuthenticated = useAuthenticationStore(
    (state) => state.isAuthenticated
  );
  useAdobeTagPageLoadEvent();

  if (!isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  if (!isUserPermittedToPath(location.pathname)) {
    redirectToLastPermittedPath();
  }

  return children;
};
