import { useMutation } from "@tanstack/react-query";
import { memberApi } from "../../api/member";
import { useRegistrationStore } from "../registration/registrationStore";
import { ResponseError } from "../common/api/ResponseError";

export const useHmpOwnedReadyMutation = () =>
  useMutation<unknown, ResponseError, void>({
    mutationFn: () => memberApi.hmpOwnedReady(),
    onSuccess: () => {
      useRegistrationStore.getState().blockRegistrationForm();
      useRegistrationStore.getState().finishRegistration();
    }
  });
