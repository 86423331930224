import { isPaymentSelected } from "../../config/hmp";

type BreadcrumbStep = {
  title: string;
  isDisplayed: boolean;
};

export const getSteps = () => {
  const breadcrumbSteps: BreadcrumbStep[] = [
    {
      title: "ONLINE_PROFILE",
      isDisplayed: true,
    },
    {
      title: "YOUR_INFO",
      isDisplayed: true,
    },
    {
      title: "GOLD_STANDARD",
      isDisplayed: true,
    },
    {
      title: "AGREEMENT",
      isDisplayed: true,
    },
    {
      title: "HMP_PURCHASE",
      isDisplayed: isPaymentSelected(),
    },
    {
      title: "CONFIRMATION",
      isDisplayed: true,
    },
  ];

  return breadcrumbSteps
    .filter((step) => step.isDisplayed)
    .map((step) => step.title);
};

export const getUrlForStep = (step: number) => {
  switch (step) {
    case 0:
      return "/register";
    case 1:
      return "/personalinfo";
    case 2:
      return "/guarantees";
    case 3:
      return "/agreement";
    case 4:
      if (isPaymentSelected()) {
        return "/payment";
      }
      return "/confirmation";
    case 5:
      return "/confirmation";
    default:
      return "/register";
  }
};
