import createDOMPurify, { Config } from "dompurify";

const DOMPurify = createDOMPurify(window);

type DomReturnType = {
  RETURN_DOM_FRAGMENT?: false;
  RETURN_DOM?: false;
};

export const sanitize = (
  html: string,
  config: Config & DomReturnType = {}
): string => DOMPurify.sanitize(html, config);
