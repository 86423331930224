import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { FieldValues } from "react-hook-form";
import { createAttachmentsFromForm } from "./attachmentMapper";

interface FileMetadata {
  name: string;
  type: string;
}
interface Attachment {
  fileMetadata: FileMetadata;
  awsKey: string;
  wasUploaded: boolean;
  name: string;
}

interface AttachmentsState {
  attachments: Attachment[];
}

interface AttachmentsAction {
  setAttachments: (personalInfoValues: FieldValues) => void;
  reset: () => void;
}

const initialState: AttachmentsState = {
  attachments: [],
};

export const useAttachmentsStore = create<
  AttachmentsState & AttachmentsAction
>()(
  devtools(
    persist(
      (set) => ({
        attachments: [],
        setAttachments: (personalInfoValues) => {
          set(createAttachmentsFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "attachments-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
