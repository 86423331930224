import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

type MUIProviderProps = {
  children: JSX.Element | JSX.Element[];
};

const theme = createTheme({
  typography: {
    body1: { color: "#515151" },
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#348feb",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: "300",
          height: "60px",
          padding: "0 10px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "300",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0 0.5rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          maxWidth: "calc(100% - 45px)",
        },
      },
    },
  },
});

export const MUIProvider = ({ children }: MUIProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
