import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { FieldValues } from "react-hook-form";
import { createTinsFromForm } from "./tinsMapper";
import {
  IDENTITY_CONFIRMATION_FAILED_ERROR_CODE,
  IDENTITY_CONFIRMATION_NOT_MATCH_ERROR_CODE,
} from "../../../types/errorCodes";

export enum IdentityConfirmationState {
  NOT_MATCH = "NOT_MATCH",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN",
}

export const ErrorCodeToIdentityConfirmationState: Record<
  string,
  IdentityConfirmationState
> = {
  [IDENTITY_CONFIRMATION_NOT_MATCH_ERROR_CODE]:
    IdentityConfirmationState.NOT_MATCH,
  [IDENTITY_CONFIRMATION_FAILED_ERROR_CODE]: IdentityConfirmationState.FAILED,
};

interface FileMetadata {
  name: string;
  type: string;
}
interface Attachment {
  fileMetadata: FileMetadata;
  awsKey: string;
  wasUploaded: boolean;
  index: number;
}
export interface RegistrationNumber {
  documentType: string;
  documentValue: string;
  issueDate: string;
  expiryDate: string;
  additional: boolean;
  attachments: Attachment[];
  index: number;
}
interface TinsState {
  isResident: boolean | null;
  registrationNumbers: RegistrationNumber[];
  identityConfirmationState: IdentityConfirmationState;
}

interface TinsAction {
  setTins: (personalInfoValues: FieldValues) => void;
  setResident: (isResident: boolean) => void;
  setIdentityConfirmationState: (
    identityConfirmationState: IdentityConfirmationState
  ) => void;
  reset: () => void;
}

const initialState: TinsState = {
  isResident: null,
  registrationNumbers: [],
  identityConfirmationState: IdentityConfirmationState.UNKNOWN,
};

export const useTinsStore = create<TinsState & TinsAction>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setTins: (personalInfoValues) => {
          set(createTinsFromForm(personalInfoValues));
        },
        setResident: (isResident) => {
          set({ isResident });
        },
        setIdentityConfirmationState: (identityConfirmationState) => {
          set({ identityConfirmationState });
        },
        reset: () => set(initialState),
      }),
      {
        name: "tins-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
