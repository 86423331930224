import React, { ReactNode } from "react";
import { DataSubmittedInfo } from "../DataSubmittedInfo";

interface ShowValidSectionProps {
  isDataSubmitted?: boolean;
  children: ReactNode;
}

export const ShowValidSection = ({
  isDataSubmitted,
  ...props
}: ShowValidSectionProps) => {
  return isDataSubmitted ? <DataSubmittedInfo /> : props.children;
};
