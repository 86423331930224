import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface ConfigurationState {
  version: string;
}
export interface ConfigurationAction {
  setVersion: (version: string) => void;
  reset: () => void;
  isVersionNotObtained: () => boolean;
}

export const useConfigurationStore = create<
  ConfigurationState & ConfigurationAction
>()(
  devtools(
    persist(
      (set, get) => ({
        version: "",
        setVersion: (version) => set({ version }),
        reset: () => set({ version: "" }),
        isVersionNotObtained: () => {
          return get().version === "";
        },
      }),
      {
        name: "configuration-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
