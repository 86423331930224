import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { useAuthorizationStore } from "../authorization/authorizationStore";

export enum RegistrationEvent {
  SmsSend = "SmsSend",
  PinCodeVerified = "PinCodeVerified",
  OnlineCodesVerified = "OnlineCodesVerified",
  PersonalInfoSubmitted = "PersonalInfoSubmitted",
  GoldStandardAccepted = "GoldStandardAccepted",
  AgreementsAccepted = "AgreementsAccepted",
  PaymentStarted = "PaymentStarted",
  PaymentDone = "PaymentDone",
  RegistrationCompleted = "RegistrationCompleted",
}

type EventLog = {
  event: RegistrationEvent;
  date: Date;
};

type EventState = {
  eventLog: EventLog[];
};

type EventAction = {
  publish: (event: RegistrationEvent) => void;
};

const handleEvent = (event: RegistrationEvent) => {
  useAuthorizationStore.getState().handleEvent(event);
};

export const useEventStore = create<EventState & EventAction>()(
  devtools(
    persist(
      (set, get) => ({
        eventLog: [],
        publish: (event) => {
          set({
            eventLog: [...get().eventLog, { event, date: new Date() }],
          });
          handleEvent(event);
        },
      }),
      {
        name: "event-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
