import * as React from "react";
import styles from "./styles.module.scss";

interface MainContainerProps {
  children: JSX.Element | JSX.Element[] | string | string[];
}

export const MainContainer = ({ children }: MainContainerProps) => (
  <section className={styles.mainContainer}>{children}</section>
);
