import * as React from "react";
import styles from "./styles.module.scss";

interface ConfirmationButtonContainerProps {
  children: React.ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

export const ConfirmationButtonContainer = ({
  children,
}: ConfirmationButtonContainerProps) => (
  <section className={styles.confirmationButtonContainer}>{children}</section>
);
