import React from "react";
import logo from "../../../assets/images/HN-logo-header.png";
import styles from "./styles.module.scss";

export const Logo = () => {
  return (
    <div className={styles.logoContainer}>
      <img className={styles.logo} src={logo} alt="Herbalife Logo" />
    </div>
  );
};
