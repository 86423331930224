import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
import { VALIDATION_RULES } from "../config/constants";

export type FormFieldError =
  | FieldError
  | Merge<FieldError, FieldErrorsImpl>
  | undefined;

export const addDaysToDate = (date: Date, days: number) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

export const getFormattedDate = (date: Date) => {
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};

export const getErrorMessage = (error: FormFieldError) => {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const errorMessage = error?.message?.toString()?.split("|")[0];
  return errorMessage && errorMessage !== "undefined" ? errorMessage : "";
};

export const getMaxLengthFor = (country: string, fieldName: string) => {
  return VALIDATION_RULES[country]?.[fieldName] as number;
};
