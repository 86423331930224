import { MutateOptions, useMutation } from "@tanstack/react-query";
import { useProfileStore } from "../profileStore";
import { ResponseError } from "../../common/api/ResponseError";
import { smsVerification } from "../../../api/smsVerification";

const { verifyPin } = useProfileStore.getState();

export type VerifyPinRequest = {
  phoneNumberCountryCode: string;
  phoneNumberSubscriberNumber: string;
  verificationCode: string;
};

export const useVerifyPinMutation = () => {
  const verifyPinMutation = useMutation<
    unknown,
    ResponseError,
    VerifyPinRequest
  >({
    mutationFn: (request: VerifyPinRequest) =>
      smsVerification.verifyPinCode(request),
    onSuccess: () => {
      verifyPin();
    },
  });

  return {
    ...verifyPinMutation,
    mutate: (
      ...params: [
        VerifyPinRequest,
        MutateOptions<unknown, ResponseError, VerifyPinRequest>,
      ]
    ) => {
      if (!verifyPinMutation.isPending) {
        verifyPinMutation.mutate(...params);
      }
    },
  };
};
