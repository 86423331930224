import { MutateOptions, useMutation } from "@tanstack/react-query";
import { sponsorApi } from "../../../../api/sponsor";
import { useSponsorshipStore } from "../sponsorshipStore";
import { ResponseError } from "../../../common/api/ResponseError";

export interface SponsorshipRequest {
  introducingMemberId: string;
  firstThreeLettersOfLastName: string;
}

const { confirmSponsorship } = useSponsorshipStore.getState();

export interface SponsorshipResponse {
  sponsor: {
    id: string;
    firstName: string;
    lastName: string;
  };
  referrer: {
    firstName: string;
    lastName: string;
  };
}

export const useConfirmSponsorshipMutation = () => {
  const confirmSponsorshipMutation = useMutation<
    SponsorshipResponse,
    ResponseError,
    SponsorshipRequest
  >({
    mutationFn: (request: SponsorshipRequest) =>
      sponsorApi.verifySponsor(
        request.introducingMemberId,
        request.firstThreeLettersOfLastName
      ),
    onSuccess: (response: SponsorshipResponse) => {
      confirmSponsorship(response);
    },
  });

  return {
    ...confirmSponsorshipMutation,
    mutate: (
      ...params: [
        SponsorshipRequest,
        MutateOptions<SponsorshipResponse, ResponseError, SponsorshipRequest>,
      ]
    ) => {
      if (!confirmSponsorshipMutation.isPending) {
        confirmSponsorshipMutation.mutate(...params);
      }
    },
  };
};
