import React from "react";

interface ExternalLinkProps {
  children: JSX.Element | JSX.Element[] | string | string[];
  linkUrl?: string;
  onClick?: () => void;
}

export const ExternalLink = ({
  children,
  linkUrl,
  onClick,
}: ExternalLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={linkUrl}
      className="external-link"
      onClick={onClick}
      data-testid="external-link"
    >
      {children}
    </a>
  );
};
