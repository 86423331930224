import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";

interface AuthenticationState {
  token: string;
}

interface AuthenticationAction {
  setToken: (token: string) => void;
  removeToken: () => void;
  getToken: () => string;
  isAuthenticated: () => boolean;
  reset: () => void;
}

export const useAuthenticationStore = create<
  AuthenticationState & AuthenticationAction
>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          token: "",
          getToken: () => {
            return get().token;
          },
          setToken: (token: string) => {
            set({ token });
          },
          removeToken: () => {
            set({ token: "" });
          },
          isAuthenticated: () => {
            return get().token !== "";
          },
          reset: () => {
            set({ token: "" });
          },
        }),
        {
          name: "authentication-storage",
          storage: createJSONStorage(() => sessionStorage),
        }
      )
    )
  )
);
