import { MarketingPayload, useMarketingStore } from "./MarketingStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export const marketingFromStore = () => {
  return {
    beenMemberBefore: useMarketingStore.getState().beenMemberBefore,
    marketingConsentAccepted:
      useMarketingStore.getState().marketingConsentAccepted,
    documentationConfirmed: useMarketingStore.getState().documentationConfirmed,
    prospectOwnsSignificantSocialMediaAccount:
      useMarketingStore.getState().prospectOwnsSignificantSocialMediaAccount,
    socialMediaConsents: useMarketingStore.getState().socialMediaConsents,
  };
};

export const createMarketingFromForm = ({
  beenMemberBefore,
  marketingConsentAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents,
}: WithTypeContaining<MarketingPayload>): MarketingPayload => ({
  beenMemberBefore,
  marketingConsentAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents,
});

export const personalInfoRelatedMarketingFields = ({
  beenMemberBefore,
  marketingConsentAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
}: WithTypeContaining<MarketingPayload>): MarketingPayload => ({
  beenMemberBefore,
  marketingConsentAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
});
