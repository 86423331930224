import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { Memberpack } from "../../models/Memberpack";
import { ShippingAddressModel } from "../models/ShippingAddressModel";

interface ShippingState {
  shippingAddress: ShippingAddressModel;
  chosenMemberpack: Memberpack;
  availableMemberpacks: Memberpack[];
  addressConfirmed: boolean;
}

interface ShippingActions {
  setAvailableMemberpacks: (memberPacks: Memberpack[]) => void;
  getMemberpackById: (id: string | null) => Memberpack | null;
  setChosenMemberpack: (memberpack: Memberpack) => void;
  cleanMemberpacks: () => void;
  setAddressConfirmed: (newAddressConfirmed: boolean) => void;
  setShippingAddress: (shippingAddress: ShippingAddressModel) => void;
  isMemberpackDefined: () => boolean;
  reset: () => void;
}

export const useShippingStore = create<ShippingState & ShippingActions>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          shippingAddress: <ShippingAddressModel>{},
          chosenMemberpack: <Memberpack>{},
          availableMemberpacks: [],
          addressConfirmed: true,
          setAvailableMemberpacks: (memberpacks: Memberpack[]) => {
            if (memberpacks) {
              set({ availableMemberpacks: memberpacks });
              if (memberpacks.length === 1) {
                set({ chosenMemberpack: memberpacks[0] });
              }
            }
          },
          getMemberpackById: (pricedMemberpackId: string | null) =>
            get().availableMemberpacks.find(
              (availableMemberpacks) =>
                pricedMemberpackId &&
                availableMemberpacks.pricedMemberpackId === pricedMemberpackId
            ) || null,
          setChosenMemberpack: (memberpack: Memberpack) => {
            set({ chosenMemberpack: memberpack });
          },
          cleanMemberpacks: () => {
            set({ chosenMemberpack: <Memberpack>{}, availableMemberpacks: [] });
          },
          setAddressConfirmed: (addressConfirmed: boolean) => {
            set({ addressConfirmed });
          },
          setShippingAddress: (shippingAddress: ShippingAddressModel) => {
            set({ shippingAddress });
          },
          isMemberpackDefined: () =>
            !!get().chosenMemberpack?.pricedMemberpackId,
          reset: () => {
            set({
              shippingAddress: <ShippingAddressModel>{},
              chosenMemberpack: <Memberpack>{},
              availableMemberpacks: [],
              addressConfirmed: true,
            });
          },
        }),
        {
          name: "shipping-storage",
          storage: createJSONStorage(() => sessionStorage),
        }
      )
    )
  )
);
