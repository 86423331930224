import { useEffect } from "react";
import {
  OLD_JOIN_HERBALIFE_ONLINE_EVENT,
  useFirebaseStore,
} from "../../features/firebase/firebaseStore";

type ExternalUrlRedirectionProps = {
  url: string;
};

export const ExternalUrlRedirection = ({
  url,
}: ExternalUrlRedirectionProps) => {
  const sendFirebaseEvent = useFirebaseStore(
    (state) => state.sendFirebaseEvent
  );

  useEffect(() => {
    sendFirebaseEvent(OLD_JOIN_HERBALIFE_ONLINE_EVENT, {});
  });
  window.location.href = url;
  return null;
};
