type InputValue = string | null | undefined;

export const sanitizeStringValue = (value: InputValue = ""): string => {
  if (!value) {
    return "";
  }

  return value.replace(/[^\p{L}\-'‘`\s]+/gu, "").replace(/\s+/g, " ");
};

export const sanitizeValueOnChange = (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  e.target.value = sanitizeStringValue(e.target.value);
};

export const sanitizeNumberValue = (value: InputValue = ""): string => {
  if (!value) {
    return "";
  }
  return value.replace(/[^0-9]/gu, "");
};

export const sanitizeValue = (value: InputValue, replacement = ""): string => {
  if (!value) {
    return "";
  }

  return value
    .replace(/[^\p{L}\-'`\s0-9]+/gu, "")
    .replace(/\s{2,}/g, " ")
    .replace(/^\s+/, replacement);
};
