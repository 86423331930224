import axios from "axios";
import { INITIAL_REGISTRATION_PATH } from "../config/api";
import { InitialRegistrationResponse } from "../features/initialRegistration/mutations/useInitialRegistrationMutation";

const getInitialRegistrationData = async () => {
  const response = await axios.get<InitialRegistrationResponse>(
    INITIAL_REGISTRATION_PATH
  );
  return response.data;
};

export const initialRegistrationApi = {
  getInitialRegistrationData,
};
