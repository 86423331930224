import axios from "axios";
import { CHECK_SPONSOR_PATH } from "../config/api";
import { getAbortController } from "../utils/abortController";
import { SponsorshipResponse } from "../features/initialRegistration/sponsorship/mutations/confirmSponsorshipMutation";

const verifySponsor = async (sponsorId: string, sponsorName: string) => {
  const response = await axios.post<SponsorshipResponse>(
    CHECK_SPONSOR_PATH,
    {
      introducingMemberId: sponsorId,
      firstThreeLettersOfLastName: sponsorName,
    },
    {
      signal: getAbortController().signal,
    }
  );
  return response.data;
};

export const sponsorApi = {
  verifySponsor,
};
