import React, { ChangeEvent } from "react";
import { Input } from "../common/Input";
import { sanitizeNumberValue } from "../../utils/replacement";
import { FormFieldError } from "../../utils/formsUtils";
import styles from "./styles.module.scss";

type PhoneNumberProps = {
  error: FormFieldError;
  id: string;
  label: string;
  maxLength: number;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPhoneNumberChange: (value: string) => void;
  phoneNumberDisabled: boolean;
  value?: string;
};

export const PhoneNumber = ({
  error,
  id,
  label,
  maxLength,
  phoneNumberDisabled,
  onBlur,
  onKeyPressed,
  onPhoneNumberChange,
  value,
}: PhoneNumberProps) => {
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    onPhoneNumberChange &&
    onPhoneNumberChange(sanitizeNumberValue(event.target?.value));

  return (
    <div className={styles.phoneNumberWrapper}>
      <Input
        disabled={phoneNumberDisabled}
        error={error}
        label={label}
        id={id}
        maxLength={maxLength}
        name="mobile"
        noLabelMargin
        type="tel"
        value={value || ""}
        placeholder=""
        onBlur={onBlur}
        onChange={handlePhoneNumberChange}
        onKeyDown={onKeyPressed}
        inputMode={"numeric"}
        pattern={"[0-9]*"}
      />
    </div>
  );
};
