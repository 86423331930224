import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { FIREBASE_CONFIG } from "../config/google";
import logger from "./logger";

const isFirebaseEnabled = () =>
  import.meta.env.VITE_FIREBASE_ENABLED === "true";

export const firebase = isFirebaseEnabled()
  ? initializeApp(FIREBASE_CONFIG)
  : undefined;

export const logEvent = (eventName: string, data: object) => {
  if (!isFirebaseEnabled() || !firebase) {
    return;
  }

  logger.info(
    `Sending firebase event: ${eventName} with parameters: ${JSON.stringify(
      data
    )}`
  );
  firebaseLogEvent(getAnalytics(firebase), eventName, data);
};
